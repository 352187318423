import { array, object, string } from 'yup'

import { ProductVariation } from '@interfaces/restaurant'
import { isMultiple, isRequired } from '../utils/helpers'

export const productValidationSchema = (
    state: ProductVariation,
    hasPriceVariations: boolean,
) => {
    const schema: Record<string, any> = {}

    if (hasPriceVariations) {
        schema.name = string().required('Necesar')
    }

    state.toppings?.forEach((topping) => {
        const min = topping.quantity_minimum
        const max =
            topping.quantity_maximum === 0
                ? topping.options.length
                : topping.quantity_maximum

        const multipleSchema = array()
            .min(min, `Necesar, minim ${min}`)
            .max(max, `Alege maxim ${max}`)

        const required = isRequired(topping)
        const multiple = isMultiple(topping)

        schema[topping.name] = multiple
            ? required
                ? multipleSchema.required('necesar')
                : multipleSchema.nullable()
            : required // single choice - radio type
            ? string().required('Alege 1')
            : string().nullable()
    })

    return object().shape(schema)
}
