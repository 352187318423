import { Option, ProductVariation, Topping } from '@interfaces/restaurant'
import { isMultiple } from '@utils/helpers'

type AddRemovePayload = {
    option: Option
    topping: Topping
}

type ChangeVariationPayload = {
    product_variation: ProductVariation
    toppings: Topping[]
}

type VariationReducerAction =
    | { type: 'CHANGE_VARIATION'; payload: ChangeVariationPayload }
    | { type: 'ADD' | 'REMOVE'; payload: AddRemovePayload }
    | { type: 'INCREMENT_QTY' | 'DECREMENT_QTY' }
    | { type: 'SPECIAL_REQUESTS'; payload: { text: string } }
    | { type: 'CLEAR' }

export const initialState: ProductVariation = {
    id: '',
    name: '',
    price: 0,
    totalPrice: 0,
    quantity: 1,
    topping_ids: [],
    choices: [],
    toppings: [],
    special_requests: '',
}

export const updatePrice = (state: ProductVariation): number => {
    return state.choices.reduce(
        (acc, topping) =>
            acc + topping.options.reduce((total, option) => total + option.price, 0),
        state.price,
    )
}

const handleAdd = (
    state: ProductVariation,
    payload: AddRemovePayload,
): ProductVariation => {
    const { topping, option } = payload
    const multiple = isMultiple(topping)

    const alreadyAddedTopping = state.choices.some((t) => t.id === topping.id)

    const updateChoices = () => {
        if (multiple) {
            // checkbox, multiple
            state.choices.map((t) =>
                t.id === topping.id ? t.options.push(option) : t,
            )
        } else {
            // radio, single,
            state.choices.map((t) =>
                t.id === topping.id
                    ? t.options.splice(0, t.options.length, option) // replace with the new radio value
                    : t,
            )
        }
    }

    const addNewChoice = () => {
        state.choices.push({
            ...topping,
            options: topping.options.filter((opt) => opt.id === option.id),
        })
    }

    alreadyAddedTopping ? updateChoices() : addNewChoice()

    return {
        ...state,
        choices: state.choices,
        totalPrice: updatePrice(state),
    }
}

const handleRemove = (
    state: ProductVariation,
    payload: AddRemovePayload,
): ProductVariation => {
    const removeOption = (t: Topping): Topping => ({
        ...t,
        options: t.options.filter((opt) => opt.id !== payload.option.id),
    })

    return {
        ...state,
        choices: state.choices
            .map((t) => (t.id === payload.topping.id ? removeOption(t) : t))
            .filter((t) => t.options.length !== 0), // remove multiple choice toppings with no options
        totalPrice: state.totalPrice - payload.option.price,
    }
}

export const variationReducer = (
    state: ProductVariation,
    action: VariationReducerAction,
) => {
    switch (action.type) {
        case 'CHANGE_VARIATION':
            return {
                ...action.payload.product_variation,
                quantity: state.quantity || 1,
                totalPrice: action.payload.product_variation.price,
                toppings: action.payload.toppings,
                choices: [],
            }

        case 'ADD':
            return handleAdd(state, action.payload)

        case 'REMOVE':
            return handleRemove(state, action.payload)

        case 'SPECIAL_REQUESTS':
            return {
                ...state,
                special_requests: action.payload.text,
            }

        case 'DECREMENT_QTY':
            return state.quantity === 1
                ? state
                : {
                      ...state,
                      quantity: state.quantity - 1,
                  }

        case 'INCREMENT_QTY':
            return {
                ...state,
                quantity: state.quantity + 1,
            }

        case 'CLEAR':
            return initialState

        default:
            return state
    }
}
