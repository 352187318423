import { FC } from 'react'

import { classes, ProductImage } from '@components/product'
import { Product } from '@interfaces/restaurant'
import { formatPrice } from '@utils/helpers'

interface Props {
    product: Product
    isDialog?: boolean
    onClick?: () => void
}

const BaseProduct: FC<Props> = ({ product, onClick, isDialog = true }) => (
    <div className={classes.product} onClick={onClick}>
        <div className={classes.title}>
            {isDialog ? <h3>{product.name}</h3> : <h1>{product.name}</h1>}
            <span>{product.description}</span>

            <p style={{ fontWeight: 500, fontSize: 'regular' }}>
                {formatPrice(product.product_variations[0].price)}
            </p>

            {product.is_sold_out && <small>Stoc epuizat</small>}
        </div>

        <ProductImage product={product} size="small" />
    </div>
)

export default BaseProduct
