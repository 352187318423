import React from 'react'
import { Field, FormikErrors } from 'formik'
import { ListItem, ListItemText, Radio, Typography } from '@material-ui/core'

import { Product, ProductVariation } from '@interfaces/restaurant'
import { formatPrice } from '@utils/helpers'
import classes from './ProductDetails.module.scss'

interface ChooseVariationProps {
    errors: FormikErrors<ProductVariation>
    product: Product
    hasPriceVariations: boolean
    onVariationChange: (
        index: number,
    ) => (event: React.FormEvent<HTMLLabelElement>) => void
}

const DetailsChooseVariation: React.FC<ChooseVariationProps> = ({
    product,
    errors,
    hasPriceVariations,
    onVariationChange,
}) => {
    if (!hasPriceVariations) return null

    return (
        <div>
            <ListItemText
                id="name"
                className={classes.topping__category}
                primary="Alege varianta"
                secondary={
                    <Typography className={errors.name ? 'error' : ''}>
                        Necesar
                    </Typography>
                }
            />

            {product.product_variations.map((variation, idx) => (
                <label key={variation.id} onChange={onVariationChange(idx)}>
                    <ListItem button>
                        <Field
                            name="name"
                            type="radio"
                            value={variation.name}
                            as={Radio}
                        />
                        <ListItemText
                            className="between"
                            primary={variation.name}
                            secondary={formatPrice(variation.price)}
                        />
                    </ListItem>
                </label>
            ))}
        </div>
    )
}

export default DetailsChooseVariation
