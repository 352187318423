import React, { useState } from 'react'
import { Field, FormikErrors } from 'formik'
import {
    Checkbox,
    IconButton,
    ListItem,
    ListItemText,
    Radio,
    Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import { Option, ProductVariation, Topping } from '@interfaces/restaurant'
import {
    formatPrice,
    isMultiple,
    isMultipleOptionsListDisabled,
    toppingCategoryText,
} from '@utils/helpers'
import classes from './ProductDetails.module.scss'

interface Props {
    productVariation: ProductVariation
    topping: Topping
    errors: FormikErrors<ProductVariation>
    onToppingsChange: (
        topping: Topping,
        option: Option,
    ) => (e: any | React.ChangeEvent<HTMLInputElement>) => void
}

const MAX_OPTIONS_COUNT = 5

const DetailsChooseToppings: React.FC<Props> = ({
    productVariation,
    topping,
    errors,
    onToppingsChange,
}) => {
    const multiple = isMultiple(topping)

    const [expanded, setExpanded] = useState(false)

    return (
        <div id={topping?.name} className={classes.topping__category__scroll}>
            <ListItemText
                className={classes.topping__category}
                primary={topping?.name}
                secondary={
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={
                            errors[topping.name]
                                ? { fontWeight: 700, color: '#fa755a' }
                                : null
                        }
                    >
                        {toppingCategoryText(topping)}
                    </Typography>
                }
            />

            {topping.options
                ?.sort((o1, o2) => o1.sortId - o2.sortId)
                ?.slice(0, expanded ? 100 : MAX_OPTIONS_COUNT)
                ?.map((option) => {
                    const disabled =
                        option.is_sold_out ||
                        isMultipleOptionsListDisabled(
                            productVariation,
                            topping,
                            option,
                        )

                    return (
                        <label
                            key={option.id}
                            onChange={onToppingsChange(topping, option)}
                        >
                            <ListItem button>
                                <Field
                                    name={topping.name}
                                    value={option.name}
                                    type={multiple ? 'checkbox' : 'radio'}
                                    as={multiple ? Checkbox : Radio}
                                    multiple={multiple}
                                    disabled={disabled}
                                    // onClick={onToppingsChange(topping, option)}
                                    // checked={productVariation[topping.name]?.includes(option.name)}
                                />

                                <ListItemText
                                    className="between"
                                    primary={option.name}
                                    secondary={
                                        option.is_sold_out
                                            ? 'indisponibil'
                                            : option.price > 0 &&
                                              `+${formatPrice(option.price)}`
                                    }
                                    style={{
                                        color: disabled ? 'darkgray' : 'black',
                                    }}
                                />
                            </ListItem>
                        </label>
                    )
                })}

            {topping.options.length > 6 && !expanded && (
                <div
                    className="center-l ml1 hover"
                    onClick={() => setExpanded(true)}
                >
                    <IconButton aria-label="expand">
                        <ExpandMore />
                    </IconButton>
                    <Typography color="secondary">
                        Arata mai multe ({topping.options.length - MAX_OPTIONS_COUNT}
                        )
                    </Typography>
                </div>
            )}
        </div>
    )
}

export default DetailsChooseToppings
