import { Menu, Topping } from '@interfaces/restaurant'
import MenuItem from './MenuItem'

interface Props {
    menus: Menu[]
    toppings: Topping[]
}
const MenuList: React.FC<Props> = ({ menus, toppings }) => (
    <div className="white">
        {menus?.map((menu) => (
            <section
                key={menu.id}
                // id={menu.name.replace(/ /g, '-').replace(/'/g, '').toLowerCase()}
                id={menu.name}
                className="category-divider"
            >
                <MenuItem menu={menu} toppings={toppings} />
            </section>
        ))}
    </div>
)

export default MenuList
