import React, { FC, useState } from 'react'
import { Grid, IconButton } from '@material-ui/core'
import { Share } from '@material-ui/icons'

import { analyticsSelectItem } from '@api/analytics'
import { CustomDialog } from '@components/common'
import { BaseProduct, classes, ProductDetails } from '@components/product'
import { useAlert } from '@context'
import { Product, Topping } from '@interfaces/restaurant'
import { formattedProductLink } from '@utils/helpers'

interface ProductProps {
    product: Product
    toppings: Topping[]
}
const ProductItem: FC<ProductProps> = ({ product, toppings }) => {
    const alert = useAlert()
    const [open, setOpen] = useState(false)
    const toggle = () => setOpen(!open)
    const [isSolidAppbar, setIsSolidAppbar] = useState(false)

    const onClick = () => {
        setOpen(!open)
        if (open) analyticsSelectItem(product)
    }

    const handleCopyToClipboard = () => {
        try {
            const url = `${window.location.origin}/${formattedProductLink(product)}`
            navigator.clipboard.writeText(url)
            alert.show('info', 'Linkul a fost copiat in clipboard')
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <Grid item xs={12} md={6} className={classes.product__divider}>
                <BaseProduct product={product} onClick={onClick} />
            </Grid>

            {open && (
                <CustomDialog
                    open={open}
                    onClose={onClick}
                    position={!product.file_path ? 'sticky' : 'absolute'}
                    title={
                        !product.file_path //no img
                            ? product.name
                            : isSolidAppbar //img
                            ? product.name
                            : null
                    }
                    rightAction={
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="share"
                            onClick={handleCopyToClipboard}
                            style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
                        >
                            <Share color="inherit" />
                        </IconButton>
                    }
                    onScrollCapture={(v) => setIsSolidAppbar(v > 230)}
                    color={isSolidAppbar ? 'inherit' : 'transparent'}
                >
                    <ProductDetails
                        product={product}
                        toppings={toppings}
                        onAddToCartClick={toggle}
                        isDialog={true}
                    />
                </CustomDialog>
            )}
        </>
    )
}

export default ProductItem
