import React, { useCallback, useEffect, useReducer } from 'react'
import { Form, Formik } from 'formik'
import { ListItemText, useMediaQuery, useTheme } from '@material-ui/core'
import { initialState, variationReducer } from 'reducers/variationReducer'
import { productValidationSchema } from 'schemas/productValidationSchema'

import { analyticsAddToCart, analyticsItemDetailsVariation } from '@api/analytics'
import { FormikFocusError } from '@components/common'
import {
    DetailsAddToCartButton,
    DetailsChooseToppings,
    DetailsChooseVariation,
    DetailsSpecialInstructions,
    ProductImage,
} from '@components/product'
import { useCart } from '@context'
import { Option, Product, ProductVariation, Topping } from '@interfaces/restaurant'
import classes from './ProductDetails.module.scss'

interface Props {
    product: Product
    choices?: Topping[]
    toppings: Topping[]
    isDialog: boolean
    onAddToCartClick: () => void
}

const ProductDetails: React.FC<Props> = (props) => {
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('xs'))

    const { product } = props
    const [state, dispatch] = useReducer(variationReducer, initialState)
    const cart = useCart()

    const hasPriceVariations = product.product_variations?.length > 1

    const handleChangeVariation = useCallback(
        async (index: number) => {
            const toppings: Topping[] = []

            const selectedVariation = product.product_variations[index]
            selectedVariation?.topping_ids?.map((toppingId) =>
                props.toppings.map((t) =>
                    t.id === toppingId ? toppings.push(t) : t,
                ),
            )

            dispatch({
                type: 'CHANGE_VARIATION',
                payload: {
                    product_variation: selectedVariation,
                    toppings: toppings,
                },
            })

            analyticsItemDetailsVariation(product, selectedVariation)
        },
        [props.toppings, product.product_variations],
    )

    useEffect(() => {
        if (!hasPriceVariations) handleChangeVariation(0)

        return () => {
            dispatch({ type: 'CLEAR' })
        }
    }, [hasPriceVariations, handleChangeVariation])

    const onVariationChange =
        (index: number) => (e: React.FormEvent<HTMLLabelElement>) => {
            e.preventDefault()
            handleChangeVariation(index)
        }

    const onToppingsChange =
        (topping: Topping, option: Option) =>
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault()
            const { checked } = e.target

            dispatch({
                type: checked ? 'ADD' : 'REMOVE',
                payload: { topping, option },
            })
        }

    const onIncrementQty = () => {
        dispatch({
            type: 'INCREMENT_QTY',
        })
    }

    const onDecrementQty = () => {
        dispatch({
            type: 'DECREMENT_QTY',
        })
    }

    const onAddSpecialInstructions = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: 'SPECIAL_REQUESTS',
            payload: {
                text: e.target.value,
            },
        })
    }

    const onSubmit = () => {
        const updatedProduct = { ...product }
        updatedProduct.product_variations = [state]
        cart.add(updatedProduct)

        if (isLargeScreen && cart.cartState.products.length === 0) {
            cart.setOpen(true)
        }

        props.onAddToCartClick()
        analyticsAddToCart(updatedProduct)
    }

    const formikProductDetailsInitialValues: ProductVariation = {
        id: '',
        price: 0,
        totalPrice: 0,
        name: state.name,
        quantity: 0,
        topping_ids: [],
        choices: props.choices,
        toppings: state.toppings,
        special_requests: '',
    }

    const titleStyle = {
        display: !props.isDialog || product.file_path ? 'flex' : 'none',
    }
    return (
        <>
            <ProductImage product={product} size="large" />

            <h1 className={classes.title} style={titleStyle}>
                {product.name}
            </h1>
            <ListItemText className="ph1 light" secondary={product.description} />
            <br />

            <Formik
                initialValues={formikProductDetailsInitialValues}
                validationSchema={productValidationSchema(state, hasPriceVariations)}
                onSubmit={onSubmit}
                validateOnMount
                enableReinitialize
            >
                {({ values, errors, isValid }) => (
                    <Form>
                        <DetailsChooseVariation
                            hasPriceVariations={hasPriceVariations}
                            errors={errors}
                            product={product}
                            onVariationChange={onVariationChange}
                        />

                        {values.toppings?.map((topping) => (
                            <DetailsChooseToppings
                                key={topping.id}
                                productVariation={values}
                                topping={topping}
                                errors={errors}
                                onToppingsChange={onToppingsChange}
                            />
                        ))}

                        <DetailsSpecialInstructions
                            onAddSpecialInstructions={onAddSpecialInstructions}
                        />

                        <DetailsAddToCartButton
                            isSoldOut={product.is_sold_out}
                            state={state}
                            isValid={isValid}
                            handleIncrementQty={onIncrementQty}
                            handleDecrementQty={onDecrementQty}
                            isDialog={props.isDialog}
                        />

                        <FormikFocusError />
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ProductDetails
