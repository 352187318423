import { FC } from 'react'
import { Grid } from '@material-ui/core'

import { ProductItem } from '@components/product'
import { Menu, Topping } from '@interfaces/restaurant'

interface ProductCategoryProps {
    menu: Menu
    toppings: Topping[]
}

const MenuItem: FC<ProductCategoryProps> = ({ menu, toppings }) => {
    return (
        <div className="container-lg pt1">
            <h2>{menu.name}</h2>
            <Grid container spacing={2}>
                {menu.products.map((product) => (
                    <ProductItem
                        key={product.id}
                        product={product}
                        toppings={toppings}
                    />
                ))}
            </Grid>
        </div>
    )
}

export default MenuItem
