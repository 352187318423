import React from 'react'
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import { ProductVariation } from '@interfaces/restaurant'
import { formatPrice } from '@utils/helpers'
import classes from './ProductDetails.module.scss'

interface Props {
    state: ProductVariation
    isValid: boolean
    isSoldOut: boolean
    isDialog: boolean
    handleIncrementQty: () => void
    handleDecrementQty: () => void
}

const DetailsAddToCartButton: React.FC<Props> = ({
    state,
    isValid,
    isSoldOut,
    isDialog,
    handleIncrementQty,
    handleDecrementQty,
}) => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const disabled = state.quantity === 1

    return (
        <div className={isDialog ? classes.sticky__footer : classes.footer}>
            <div className={classes.fab__container}>
                <IconButton
                    aria-label="add"
                    size={isSmallScreen ? 'small' : 'medium'}
                    style={{ backgroundColor: '#eaeaea' }}
                    onClick={handleDecrementQty}
                    disabled={disabled}
                >
                    <RemoveIcon color={disabled ? 'disabled' : 'action'} />
                </IconButton>

                <span className="mh05 noselect font__x__large">
                    {state.quantity}
                </span>

                <IconButton
                    aria-label="remove"
                    size={isSmallScreen ? 'small' : 'medium'}
                    style={{ backgroundColor: '#eaeaea' }}
                    onClick={handleIncrementQty}
                >
                    <AddIcon color="primary" />
                </IconButton>
            </div>

            <div className={classes.cart__btn}>
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    style={{ minHeight: 50 }}
                    color={isValid ? 'primary' : 'default'}
                    fullWidth
                    disabled={isSoldOut}
                >
                    {isSoldOut ? 'Stoc epuizat' : 'Adauga in cos'}

                    <span className={classes.price}>
                        {state.price > 0 &&
                            formatPrice(state.totalPrice * state.quantity)}
                    </span>
                </Button>
            </div>

            <div style={{ scrollMarginBlockEnd: 100 }} />
        </div>
    )
}

export default DetailsAddToCartButton
