import React from 'react'
import { ListItemText, TextField, Typography } from '@material-ui/core'

import classes from './ProductDetails.module.scss'

interface SpecialInstructionsProps {
    onAddSpecialInstructions: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const DetailsSpecialInstructions: React.FC<SpecialInstructionsProps> = ({
    onAddSpecialInstructions,
}) => {
    return (
        <div className={classes.special_instructions_container}>
            <ListItemText
                className={classes.topping__category}
                primary="Instructiuni speciale"
                secondary={
                    <Typography>Vom face tot posibilul sa le indeplnim</Typography>
                }
            />
            <TextField
                fullWidth
                variant="outlined"
                onChange={onAddSpecialInstructions}
                placeholder="Instructiuni..."
                multiline
                minRows={3}
                maxRows={3}
                style={{ padding: '1em' }}
            />
        </div>
    )
}

export default DetailsSpecialInstructions
